@import "../../styles/vars";

.wrapper {
    background-color: $color-orange !important;
    border-radius: 36px !important;
    color: $color-white !important;
    font-weight: 600 !important;
    font-size: 0.6em;
    line-height: 1.2em !important;
}
.wrappersuccess {
    @extend .wrapper;
    background-color: $color-violet !important;
}
.wrappererror {
    @extend .wrapper;
    background-color: $color-red !important;
}
