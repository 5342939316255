@font-face {
    font-family: "System85 Pro";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src:
        url("system85-regular-pro.woff2") format("woff2"),
        url("system85-regular-pro.woff") format("woff"),
        url("system85-regular-pro.ttf") format("truetype"),
        url("system85-regular-pro.eot") format("embedded-opentype");
}

@font-face {
    font-family: "System85 Pro";
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src:
        url("system85-medium-pro.woff2") format("woff2"),
        url("system85-medium-pro.woff") format("woff"),
        url("system85-medium-pro.ttf") format("truetype"),
        url("system85-medium-pro.eot") format("embedded-opentype");
}

@font-face {
    font-family: "System85 Pro";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src:
        url("system85-bold-pro.woff2") format("woff2"),
        url("system85-bold-pro.woff") format("woff"),
        url("system85-bold-pro.ttf") format("truetype"),
        url("system85-bold-pro.eot") format("embedded-opentype");
}

@font-face {
    font-family: "System85 Pro";
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src:
        url("system85-bold-italic-pro.woff2") format("woff2"),
        url("system85-bold-italic-pro.woff") format("woff"),
        url("system85-bold-italic-pro.ttf") format("truetype"),
        url("system85-bold-italic-pro.eot") format("embedded-opentype");
}

@font-face {
    font-family: "System85 Mono Pro";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src:
        url("system85-mono-regular-pro.woff2") format("woff2"),
        url("system85-mono-regular-pro.woff") format("woff"),
        url("system85-mono-regular-pro.ttf") format("truetype"),
        url("system85-mono-regular-pro.eot") format("embedded-opentype");
}
