@import "../public/assets/fonts/styles";
@import "./vars";
@import "./mixins";

html,
body,
#__next {
    background-color: $color-white;
}

#__next {
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    //overflow-x: hidden;
}

html {
    @include font-common;
    font-size: min(calc(1em + 1vw), 1.8em);
}

a {
    color: inherit;
}

h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: 400;
}

[data-svg-icon-group="true"] {
    [data-theme="theme-light"] &,
    [data-theme="theme-system"] & {
        fill: $color-black;
    }

    [data-theme="theme-dark"] &,
    [data-theme="theme-black"] & {
        fill: $color-white;
    }
}
