@import "../../styles/vars";
@import "../../styles/mixins";

$sizes: xs, s, m, l, xl;

@mixin base-size($size) {
    @if $size == xs {
        font-size: 0.3vmin;
    } @else if $size == s {
        font-size: 0.5vmin;
    } @else if $size == m {
        font-size: 1vmin;
    } @else if $size == l {
        font-size: 2vmin;
    } @else if $size == xl {
        font-size: 3vmin;
    }
}

@function unit($size, $multiplier: 1) {
    @if $size == xs {
        @return 0.24em * $multiplier;
    } @else if $size == s {
        @return 0.18em * $multiplier;
    } @else if $size == m {
        @return 0.12em * $multiplier;
    } @else if $size == l or $size == xl {
        @return 0.06em * $multiplier;
    }
}

@function shadow-unit-blur($size) {
    @if $size == xs or $size == s or $size == m {
        @return 0.10em;
    } @else if $size == l or $size == xl {
        @return 0.05em;
    }
}

@function reflection-unit-blur($size) {
    @if $size == xs or $size == s or $size == m {
        @return 0.06em;
    } @else if $size == l or $size == xl {
        @return 0.03em;
    }
}

@keyframes rotate {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

.wrapper {
    position: relative;
    width: 10em;
    height: 10em;
    filter: blur(0.03em);

    &[data-size="xs"] {
        @include base-size(xs);
    }

    &[data-size="s"] {
        @include base-size(s);
    }

    &[data-size="m"] {
        @include base-size(m);
    }

    &[data-size="l"] {
        @include base-size(l);
    }

    &[data-size="xl"] {
        @include base-size(xl);
    }
}

.body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2.2em solid rgba($color-gray, 0.2);
    border-radius: 50%;
    transform: rotateZ(-5deg);
    box-shadow: -0.3em -0.6em 0.6em 0 rgba($color-black, 0.05);

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% + 4.36em);
        height: calc(100% + 4.36em);
        border-radius: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    @each $size in $sizes {
        [data-size="#{$size}"] & {
            &:before {
                box-shadow:
                    /* outer circle vertical highlights */
                    0 unit($size) shadow-unit-blur($size) 0 rgba($color-white, 0.4),
                    inset 0 unit($size, 2) shadow-unit-blur($size) 0 rgba($color-white, 0.4),
                    0 unit($size) shadow-unit-blur($size) 0 rgba($color-white, 0.4),
                    inset 0 -#{unit($size, 2)} shadow-unit-blur($size) 0 rgba($color-white, 0.4);
            }

            &:after {
                box-shadow:
                    /* outer circle side shadows */
                    unit($size) 0 shadow-unit-blur($size) 0 rgba($color-black, 0.2),
                    inset -#{unit($size)} 0 shadow-unit-blur($size) 0 rgba($color-black, 0.2),
                    -#{unit($size)} 0 shadow-unit-blur($size) 0 rgba($color-black, 0.2),
                    inset unit($size) 0 shadow-unit-blur($size) 0 rgba($color-black, 0.2);
            }
        }
    }
}

.inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 0.08em);
    height: calc(100% + 0.08em);
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
    box-shadow: inset -0.3em -0.6em 0.6em 0 rgba($color-black, 0.1);

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    @each $size in $sizes {
        [data-size="#{$size}"] & {
            &:before {
                box-shadow:
                    /* outer circle vertical highlights */
                    0 -#{unit($size, 2)} shadow-unit-blur($size) 0 rgba($color-white, 0.4),
                    inset 0 unit($size) shadow-unit-blur($size) 0 rgba($color-white, 0.4),
                    0 unit($size, 2) shadow-unit-blur($size) 0 rgba($color-white, 0.4),
                    inset 0 -#{unit($size)} shadow-unit-blur($size) 0 rgba($color-white, 0.4),
            }

            &:after {
                box-shadow:
                    /* outer circle side shadows */
                    unit($size) 0 shadow-unit-blur($size) 0 rgba($color-black, 0.2),
                    inset -#{unit($size)} 0 shadow-unit-blur($size) 0 rgba($color-black, 0.2),
                    -#{unit($size)} 0 shadow-unit-blur($size) 0 rgba($color-black, 0.2),
                    inset unit($size) 0 shadow-unit-blur($size) 0 rgba($color-black, 0.2);
            }
        }
    }
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    filter: blur(0.03em);

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include animation(rotate, 1s);
    }

    &:before {
        height: 50%;
        background: radial-gradient(at 50% 10%, rgba($color-white, 0.2) 10%, rgba($color-white, 0) 45%);
        transform-origin: 50% 100%;
        clip-path: circle(62% at 50% 100%);
    }

    &:after {
        border-radius: 50%;
        border: 2.2em solid $color-cyan;
        clip-path: polygon(50% 50%, 70% 0, 30% 0);
    }
}

.reflections {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(0.05em);
    border-radius: 50%;
    overflow: hidden;
    background:
        linear-gradient(35deg, rgba($color-white, 0.4) 0%, rgba($color-white, 0) 33%),
        linear-gradient(20deg, rgba($color-white, 0.4) 0%, rgba($color-white, 0) 33%),
        linear-gradient(50deg, rgba($color-white, 0.4) 0%, rgba($color-white, 0) 33%);

    &:before,
    &:after {
        top: 50%;
        left: 50%;
        content: "";
        border-radius: 50%;
        position: absolute;
    }

    &:before {
        width: 94%;
        height: 94%;
        clip-path: polygon(0 0, 70% 0, 0 100%, 100% 5%, 100% 25%, 0 100%);
        transform: translate3d(-50%, -50%, 0) rotateZ(-10deg);
    }

    &:after {
        width: 64%;
        height: 64%;
        transform: translate3d(-50%, -50%, 0) rotateZ(-20deg);
        clip-path: polygon(0 0, 50% 0, 70% 100%, 0 100%);
    }

    @each $size in $sizes {
        [data-size="#{$size}"] & {
            &:before {
                box-shadow:
                    inset 0 unit($size) 0 0 rgba($color-white, 0.8),
                    inset 0 unit($size, 2) reflection-wnit-blur($size) 0 rgba($color-white, 0.4);
            }

            &:after {
                box-shadow:
                    inset 0 -#{unit($size)} 0 0 rgba($color-white, 0.8),
                    inset 0 -#{unit($size, 2)} reflection-unit-blur($size) 0 rgba($color-white, 0.4);
            }
        }
    }
}
